const config = {
    apiServerUrl: 'https://ips.navigine.com/client',
    floorPath: 'sublocations/get',
    trackedObjectsPath: 'tracked-objects',
    trackingServerUrl: 'https://rtls.navigine.com/tracking/v1',
    userHash: 'D8CA-9260-68D1-0494',
    sublocationId: 12,
    appKey: 'a1534fa7-0b87-6363-bf19-ab30578d607a',
    objectsActiveTime: 60,
    timerDelay: 1, // in seconds
};

export default config;
