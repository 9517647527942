const get = async (api, endPoint, params = {}) => {
    try {
        let queryString = '';
        if (params) {
            queryString = '?';
            Object.keys(params).map((key, index, array) => {
                const andSymbmol = index + 1 === array.length ? '' : '&';
                queryString += `${key}=${params[key]}${andSymbmol}`;
                return key
            });
        }
        const response = await fetch(`${api}/${endPoint}${queryString}`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
            }
        })

        if (response.status >= 400) {
            const bodyError = await response.json();
            throw new Error(bodyError)
        }

        return await response.json();
    } catch (err) {
        console.warn('http error ', err);

        throw new Error(err.message)
    }
};

export default get;
