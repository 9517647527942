import './App.css';
import get from "./http";
import config from "./config";
import React, { useState, useEffect, useRef } from "react";
import OpenLayersMap from '@navigine/openlayers-navigine';
function App() {
  const mapElement = useRef();
  const [map, setMap] = useState({});
  const [objectTracked, setObjectTracked] = useState([]);
  const [proxy, setProxy] = useState([]);
  const [sublocation, setSublocation] = useState({});
  const [zoom, setZoom] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const paramsForFloor = {
      userHash: config.userHash,
      sublocationId: config.sublocationId,
    };
    const getSublocation = async () => {
      await get(config.apiServerUrl, config.floorPath, paramsForFloor).then(
        async (responseFloor) => {
          const { sublocation } = responseFloor; 
          setSublocation(sublocation);
        }
      );
    };
    getSublocation();
  }, []);

  useEffect(() => {
       if (map instanceof OpenLayersMap) {
      map.destroyMap();
    }
  if (sublocation.dimensions_in_pixels) {
    const newMap = new OpenLayersMap({
      target: mapElement.current,
      imageURL: sublocation.files[0].full_url,
      imageWidth: sublocation.dimensions_in_pixels.width,
      imageHeight: sublocation.dimensions_in_pixels.height,
      imageBounds: sublocation.image_bounds,
      lat: sublocation.lat,
      lon: sublocation.lon,
      w: sublocation.dimensions_in_meters.width,
      h:sublocation.dimensions_in_meters.height,
      hor: sublocation.hor,
      isGlobalMap: false,
      styleUrl:{},
      // token:{},
      translate: {},
      isAnimate: true,
    });
    const olmap = newMap.map;

    for (let i = 0; i < 5; i++) {
      olmap.getControls().forEach(control => {
        olmap.removeControl(control);
      });
  
      olmap.getInteractions().forEach(interaction => {
        olmap.removeInteraction(interaction);
      });
    };

    setMap(newMap);
    getTrackedObject()
  }

}, [sublocation]);

const paramsForTrackedObjects = {
  api_key: config.appKey,
  "filter%5Bsublocation.id%5D": config.sublocationId,
  actv: config.objectsActiveTime,
};
const getTrackedObject = async () => {
  await get(config.trackingServerUrl, config.trackedObjectsPath, paramsForTrackedObjects).then(
    async (response) => {
      const { data } = response;
      console.log(data)
      if(data.length < 1){
        setError(true)
      }else{
        setError(false)
        setProxy(data)
      }
    }
  );
  setTimeout(getTrackedObject.bind(this), 1000);
};

  useEffect(() => {
    
    if (map instanceof OpenLayersMap) {
      if (objectTracked.length > 0) {
        const updatedObjects = {};
        objectTracked.forEach((currentObjectElement) => {
          const objectOnMap = map.getFeaturePayload(
            map.getTrackedObjectFeature(currentObjectElement.id),
          );
          if (!objectOnMap) {
            map.addTrackedObject(currentObjectElement);
            return;
          }
  
          objectOnMap.updateTrackedObject(currentObjectElement);
          updatedObjects[currentObjectElement.id] = true;
          map.addTrackHistory();
          if(zoom){
              map.map.getView().setZoom(map.map.getView().getZoom() - 1)
          }
          setZoom(false);
        });
      }
    }

  }, [map, objectTracked]);
  useEffect(() => {
    setObjectTracked(proxy)
  },[proxy])
 
  return (
    <div className="App">
      {/* {!error ? <div ref={mapElement} className={"Map_container"} /> : 
      <div className='error'>
        <div> <h3>The train <br /> is <br /> undergoing maintenance</h3></div>
        </div> 
      } */}
      {/* <div ref={mapElement} className={"Map_container"} /> */}
      <div style={{ display: error ? 'none' : 'flex' }} ref={mapElement} className={"Map_container"} />
      <div  style={{ display: error ? 'flex' : 'none' }} className='error'>
        <div> <h3>The train <br /> is <br /> undergoing maintenance</h3></div>
        </div> 
    </div>
  );
}

export default App;
